import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer'

const IzbaPamieciBOTPage = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO title="Izba Pamięci BOT" />
      <ReturnToPrevPage to={'/gminy/minsk-mazowiecki'}>
        Materiały dotyczące miasta Mińsk Mazowiecki oraz otaczającej go gminy
      </ReturnToPrevPage>
      <Header1>Izba Pamięci BOT</Header1>
      <Img fluid={data.file.childImageSharp.fluid} />
      <MonumentContentWrapper>
        <p>
          Najważniejszymi zadaniami jakie postawiono przed nową jednostką
          wojskową stacjonującą w mieście nad Srebrną były doskonalenie systemu
          reagowania kryzysowego, kształtowanie postaw proobronnych wśród
          społeczeństwa, szkolenie i przygotowanie rezerw osobowych oraz
          szkolenie programowego pododdziałów: piechoty zmotoryzowanej,
          rozpoznawczych, łączności i logistycznych. Pierwszym dowódcą Brygady
          został pułkownik Jan Fudalej. Rola jego następcy przypadła natomiast
          pułkownikowi Zygmuntowi Mochockiemu. Warto zauważyć, że etatowy stan
          2MMBOT osiągnęła na początku 1997 roku. Rok później włączono do niej
          Reprezentacyjny Szwadron Kawalerii Wojska Polskiego.
        </p>
        <p>
          Ważny moment w dziesięcioletnich dziejach oddziału stanowiło wręczeniu
          mu sztandaru. Okolicznościowa uroczystość odbyła się 12 września 1996
          roku. Rodzicami chrzestnymi pamiątkowego przedmiotu zostali poseł na
          Sejm RP Antoni Jan Tarczyński oraz przedstawicielka rodziny Kleebergów
          Anna Kleeberg. Obecnie sztandar znajduje się w zbiorach Muzeum Wojska
          Polskiego w Warszawie. Więcej informacji na jego temat można znaleźć
          tutaj:{' '}
          <a
            href="https://tradycje.wp.mil.pl/pl/6_546.html"
            target="_blank"
            rel="nofollow"
          >
            https://tradycje.wp.mil.pl/pl/6_546.html
          </a>
          . Wspomniane wydarzenie zaowocowało również nadaniem Brygadzie miana
          Mińsko-Mazowiecka oraz ustanowieniem jej patrona w osobie dowódcy
          Samodzielnej Grupy Operacyjnej „Polesie” w kampanii polskiej 1939 roku
          generała dywizji Franciszka Kleeberga.
        </p>
        <p>
          Należy wspomnieć, że 2MMBOT odziedziczyła tradycje 50 Dywizji Piechoty
          Rezerwowej „Brzoza”. Jednostka ta we wrześniu 1939 roku weszła w skład
          SGO „Polesie”. – Przejmując 12 września 1996 roku wspaniałe
          dziedzictwo tradycji dywizji „Brzoza” wpisujecie na pierwszą kartę
          historii Waszej nowo sformowanej jednostki bojowej tradycje żołnierzy
          gen. Franciszka Kleeberga. Tych, którzy potrafili być wierni do końca.
          (…) (…) Przejmując tradycje „Kleeberczyków” i imię ich dowódcy
          generała dywizji Franciszka Kleeberga czynicie wszystko, aby być ich
          godnymi następcami. Niech czyny bojowe i postawa Patrona Brygady na
          polu walki i w niewoli niemieckiej, legendarnego dowódcy Samodzielnej
          Grupy Operacyjnej „Polesie” gen. dyw. Franciszka Kleeberga będą dla
          Was zobowiązaniem i moralnym wzorem w codziennej służbie ku chwale
          Najjaśniejszej Rzeczypospolitej – napisał w okolicznościowym
          dokumencie podsekretarz stanu w Ministerstwie Obrony Narodowej Andrzej
          Załucki.
        </p>
        <p>
          W pomieszczeniu poświęconym Brygadzie można obejrzeć m.in. dokumenty
          potwierdzające nadanie jednostce sztandaru oraz jej historycznego
          dziedzictwa. Ważny element wyposażenia sali stanowią przykłady
          polowego i galowego umundurowania żołnierzy i oficerów 2MMBOT, a także
          co ciekawe galowy strój majora żandarmerii amerykańskiej. Uwagę osób
          odwiedzających to miejsce mogą zwrócić ponadto liczne fotografie
          dokumentujące zarówno codzienne funkcjonowanie jednostki, jak i
          uroczystości z udziałem jej przedstawicieli. Na zaprezentowanych
          zdjęciach uwieczniono także dowódców Brygady.
        </p>
        <p>
          Na ścianie pomieszczenia wyeksponowano z kolei portret przedstawiający
          gen. dyw. Franciszka Kleeberga. Wśród eksponatów znajdujących się w
          izbie pamięci znalazły się również proporczyki, puchary i statuetki
          świadczące o sukcesach sportowych i pozasportowych osiąganych przez
          2MMBOT oraz reprezentacyjny szwadron kawalerii. Całość uzupełniają
          elementy wyposażenia żołnierskiego np. manierka i telefon polowy oraz
          łuski pozostałe po różnych pociskach. Salę pamięci 2
          Mińsko-Mazowieckiej Brygady Obrony Terytorialnej im. gen. dyw.
          Franciszka Kleeberga można zwiedzać w czasie godzin pracy Działu 7
          Pułku Ułanów Lubelskich Muzeum Ziemi Mińskiej w Mińsku Mazowieckim.
        </p>
      </MonumentContentWrapper>
      <StyledGallery images={images} />
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/featured/" }
      relativePath: { regex: "/minsk-mazowiecki/bot-izba-pamieci/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(
      filter: { relativePath: { regex: "/minsk-mazowiecki/bot-izba-pamieci/" } }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default IzbaPamieciBOTPage
